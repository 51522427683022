import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_STORES } from "../../api/graphql/stores";
import { IStore } from "../../@types/graphql";
import { ButtonSwitcher } from "../../components/common/buttonSwitcher";
import { FullContainerSpinner } from "../../components/common/fullContainerSpinner";

import "../../styles/pages/storePage.css";
import { SearchNotFound } from "../../components/common/searchNotFound";

export const btns = [
  { name: "Магазины", value: "stores" },
  { name: "Компании", value: "companies" },
];

export const StorePage = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery<{ stores: IStore[] }>(GET_STORES);
  const stores = data?.stores || [];

  return (
    <div className="store-page default-page">
      <h2 className="mainTitle">Магазины</h2>
      <div className="custom-store-page-actions">
        <ButtonSwitcher
          value="stores"
          items={btns}
          onChange={(i) => navigate(`/${i.value}`)}
        />
      </div>

      {loading && <FullContainerSpinner />}
      {stores.length > 0 && (
        <div className="custom-store-cards">
          {stores.map((i) => (
            <div className="custom-store-card" key={i.sellerClientId}>
              <div className="custom-store-name">{i.name}</div>
              <div className="custom-store-seller-id">
                Seller ID: {i.sellerClientId}
              </div>

              <a
                rel="noreferrer"
                target="_blank"
                href={`https://www.ozon.ru/seller/${i.sellerClientId}`}
              >
                Перейти в магазин
              </a>
            </div>
          ))}
        </div>
      )}
      {!stores.length && !loading && <SearchNotFound />}
    </div>
  );
};
